<!--
 * @Descripttion: (评价置顶)
--> 
<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div class="pages" v-loading="loading">

      <table-height-auto>
        <template slot="before">
          <el-form :inline="true" class="demo-form-inline">
                <el-form-item>
                  <el-select v-model="searchData.searchType"
                             placeholder="请选择关键字类型"
                             style="width: 140px">
                    <el-option
                      v-for="item in searchKeyType"
                      :disabled="item.disabled"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>

              <el-form-item>
                <el-input class=""
                          placeholder="搜索关键字"
                          prefix-icon="el-icon-search"
                          v-model="searchData.searchValue"
                          @keyup.enter.native="search"
                          style="width: 260px">
                </el-input>
              </el-form-item>

              <el-form-item>
                <el-select v-model="searchData.auditStatus"
                           placeholder="审核状态"
                           style="width: 120px">
                  <el-option
                    v-for="item in brandReviewStatusArray"
                    :disabled="item.disabled"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" round @click="search">查询</el-button>
              </el-form-item>
          </el-form>
        </template>

        <template v-slot:table="row">
          <el-table
            :data="tableData"
            :height="row.heightText"
            v-if="row.height">
            <el-table-column
              prop="createTime_text"
              label="日期"
              width="140">
            </el-table-column>
            <el-table-column
              prop="goodsId"
              label="商品id"
              width="140">
              </el-table-column>
              <el-table-column
                prop="nickName"
                label="昵称"
                width="150">
              </el-table-column>
            <el-table-column
              prop="credentials"
              label="用户上传凭证"
              width="180">
              <template slot-scope="scope">
                <template v-for="img in scope.row.credentialsArr">
                <img :src="img" style="display: inline-block;width: 50px;height: 50px;object-fit: cover;margin-right: 10px;"/>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              prop="evaluationMessage"
              label="评价语"
              width="280">
                <template slot-scope="scope">
                  <el-popover
                    placement="top-start"
                    title="评价语"
                    trigger="hover">
                    <div class="text-line-hide" slot="reference" style="cursor: pointer;">{{scope.row.evaluationMessage}}</div>
                    <div>{{scope.row.evaluationMessage}}</div>
                  </el-popover>
                </template>
            </el-table-column>
            <el-table-column
              prop="evaluationStar"
              label="商品评价"
              width="140">
              <template slot-scope="scope" >
                {{['','非常差','差','一般','很好','非常好'][scope.row.evaluationStar]}}
              </template>
            </el-table-column>

            <el-table-column
              label="是否匿名"
              width="90">
                <template slot-scope="scope">
                  {{recommendStatus[scope.row.isAnonymous]}}
                </template>
            </el-table-column>

            <el-table-column
              label="添加到详情页推荐位"
              width="90">
                <template slot-scope="scope">
                  {{recommendStatus[scope.row.isRecommend]}}
                </template>
            </el-table-column>

            <el-table-column label="操作" width="150">
              <template slot-scope="scope">
                <el-button size="small" type="primary"  @click="add(scope.row)" v-if="scope.row.isRecommend === 2" >添加到详情页推荐位</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>

        <template slot="after">
          <div class="paging-row">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage"
                :page-sizes="[10, 15, 20]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="totalListNum">
              </el-pagination>
          </div>
        </template>
      </table-height-auto>

    </div>
  </template>

  <script>
    import {URL} from '../../../config'
    import {disposeSearchData,parseTimeArray} from '../../../assets/js/utils'
    import {brandReviewStatus,brandReviewStatusArray,recommendStatus,recommendStatusArray} from '../../../assets/js/status'

	export default {
      name: 'evaluationList',
      components: {

      },
      data() {
        return {
          tableData: [],
          loading: false,
            value: '',
          searchKeyType:[
            {id: 'supplierStoreId', name: '商家店铺编号'},
            {id: 'goodsId', name: '商家编号'},
          ],
          recommendStatus,
          recommendStatusArray,
          brandReviewStatus,
          brandReviewStatusArray:brandReviewStatusArray.filter(res=> res.id === 2),
          goodsId:'',
          searchData:{
            searchType:'goodsId',
            searchValue:'',
            auditStatus:2,
          },

          /* 分页参数 */
          pageSize:10,
          totalListNum:10,
          currentPage: 1,
        }
      },
      created() {
         this.getList();
      },
      methods:{
        /**
         * 拼接搜索参数
         */
        jointSearchData(){
          let data = {
            pageNo:this.currentPage,
            pageSize:this.pageSize,
          };
          data = Object.assign(data,this.searchData);
          disposeSearchData(data);
          delete data.selectTime;
          return data;
        },
        /**
         * 搜索
         */
        search(){
          this.currentPage = 1;
          this.getList()
        },

        /**
         * 查询列表数据
         */
        getList(){
          return new Promise((resolve, reject) => {
            let data = this.jointSearchData();
            this.loading = true;
            this.axios.get(URL.suppEvaluationAudit.approvedList,{params:data}).then(res=>{
               let list = res.data.records || [];
              // parseTimeArray(res.data.records,["createTime"]);
              list.forEach(res=>{
                res.credentialsArr = []
                if(res.credentials){
                  try{
                    res.credentialsArr = JSON.parse(res.credentials);
                  }catch(e){

                    }
                 }
              });
              console.info(res.data.records);
              parseTimeArray(list,['createTime'],'YYYY-MM-DD')
              this.tableData = list;
              this.tableData = res.data.records;
              resolve(res);
            }).catch(()=>{
              this.tableData = [];
            }).finally(()=>{
              this.loading = false;
            })
          });
        },
        /* 分页 */
        handleSizeChange(val){
          this.pageSize = val;
          this.currentPage = 1;
          this.getList()
        },
        handleCurrentChange(val){
          this.currentPage = val;
          this.getList();
        },
        add(row){
          this.$confirm('确认添加到商品详情页推荐位(最多放置两条)','提示',{
            confirmButtonText: '确认添加',
            type: 'success'
          }).then(() => {
            this.loading = true
            let data = {
              goodsId:row.goodsId,
              id:row.id,
            };
            this.axios.post(URL.suppEvaluationAudit.isRecommend, this.qs.stringify(data)).then(res=>{
              // debugger
                if(res.code === 0){
                    this.getList();
                }else{
                  this.$message.error(res.isRecommend);
                }
            });
          }).then(()=>{
            this.getList()
          }).catch(res=>{

          }).finally(()=>{
            this.loading = false
          })
        }
      }
    }
  </script>

<style>
</style>
